.industry-card-section{
    margin-top: 10vh;
    height: 90vh;
    width: 75vw;
    background: rgb(26, 26, 26);
    display: flex;
    flex-direction: row;
    transition: ease all 2s;
    border: solid 0.5px rgb(59, 59, 59);
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    transition: all 1s ease;
    position: relative;
    z-index: 1;
}

.ic-left-section{
    height: 100%;
    width: 50%;
    /* background-color: rgb(0, 0, 0); */
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    border-radius: 15px;
    z-index: 3;
}

.transform-ic-left{
    transform: translateX(100%);
}

.ic-right-section{
    height: 100%;
    width: 50%;
    /* background-color: rgb(23, 23, 23); */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0vmin 5vmin 0vmin 10vmin;
    border-radius: 15px;
    z-index: 3;
}

.transform-ic-right{
    transform: translateX(-100%);
}

.background-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    opacity: 0;
    z-index: 3;
    transition: opacity 2s ease;
    border-radius: 15px;
    /* background-image: radial-gradient(circle, hsl(268, 90%, 49%) 50%, 0%, rgb(108, 0, 216) 40%); */
      
}

.ic-mobile-screen{
    width: auto;
    height: 80%;
    opacity: 1;
    z-index: 3;
    transition: opacity 1.5s ease;
}

.ic-details-container{

}

.ic-name{
    width: fit-content;
    font-family: "WixMadeforDisplay-Bold";
    font-size: 125%;
    word-spacing: 1px;
    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.ic-tagline{
    font-family: "WixMadeforDisplay-bold";
    font-size: 200%;
    word-spacing: 1px;
    color: rgb(255, 255, 255);
}

.ic-description{
    font-family: "WixMadeforDisplay-regular";
    font-size: 100%;
    word-spacing: 1px;
    color: rgb(184, 184, 184);
}

.ic-points-container{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}

.ic-point-tick{
    background-size: cover; 
    background-color: inherit;
    height: 2.1vh;
    width: 2.1vh;
    margin: 5px;
    margin-top: 1.5vh;
    margin-right: 1vh;
}

.ic-point{
    margin-top: 1vh;
    font-family: "WixMadeforDisplay-regular";
    font-size: 95%;
    word-spacing: 1px;
    color: rgb(221, 220, 220);
}
