@font-face {
    font-family: "lato-regular";
    src: url(.//fonts/lato-regular.woff2);
}

@font-face {
    font-family: "lato-light";
    src: url(.//fonts/Lato-Light.ttf);
}

@font-face {
    font-family: "manrope-light";
    src: url(.//fonts/manrope-light.otf);
}


@font-face {
    font-family: "WixMadeforDisplay-Regular";
    src: url(.//fonts/WixMadeforDisplay-Regular.ttf);
}

@font-face {
    font-family: "WixMadeforDisplay-Bold";
    src: url(.//fonts/WixMadeforDisplay-Bold.ttf);
}

@font-face {
    font-family: "WixMadeforDisplay-extraBold";
    src: url(.//fonts/WixMadeforDisplay-ExtraBold.ttf);
}


.feature-section-parent{
    z-index: -10;
    background-color: rgb(0, 0, 0);
    padding-top: 5vh;
    padding-bottom: 0vh;

    /* background-image: url("../assert/images/bg1.png"); */

    /* animation: changeBackground 1s linear infinite; */
    /* animation-delay: 5s; */

    /* margin: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(145deg, var(--gradient-start), var(--gradient-mid) 48%, var(--gradient-end) 76%, var(--gradient-end) 92%),
    linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
    var(--dot-color);
  background-blend-mode: overlay; /* Overlay the dots on top of the gradient */

  font-family: 'Arial', sans-serif;
  color: white;
  text-align: center; 
  /* animation:  changeBackgrounds 5s infinite; */
}

@keyframes changeBackgrounds {
    0% { background-image: url("../assert/images/bg1.png"); }
    1.33% { background-image: url("../assert/images/bg2.png"); }
    3.66% { background-image: url("../assert/images/bg3.png"); }
    5% { background-image: url("../assert/images/bg4.png"); }
    7% { background-image: url("../assert/images/bg1.png"); }
    8%, 100% { background-image: none; }
}

.feature-section{
    z-index: 2;
    opacity: 1;
    overflow: hidden;
    height: 90vh;
    width: 85vw;
    border-radius: 15px;
    background: rgb(0, 0, 0);
    background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, ) 100%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(150deg, rgba(83, 132, 255, 0.5) 0%, rgb(255, 86, 64) 100%);

    transform: rotateX(10deg); /* Use 90deg for horizontal rotation */


   
    background: rgb(16, 16, 16);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;

    border: solid 0.2px rgb(49, 49, 49);
    background: linear-gradient(180deg, rgb(15, 15, 15) 0%, rgb(10, 10, 10) 70%);

    /* background: linear-gradient(180deg, rgb(19, 19, 19) 0%, rgb(0, 0, 0) 70%); */

    /* box-shadow: 0 10px 20px rgba(255, 255, 255, 0.5); */ 
    /* box-shadow: 0 0 20px 0 rgba(255, 111, 111, 0.2); */
    /* transition: ease border 2s; */
    /* transition: ease clip-path 3s;
    animation: samp 5s infinite reverse; */
   
}

@keyframes samp {
    0%{
        border-right: solid 3px rgb(223, 56, 56);
     background: rgb(16, 16, 16);transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1deg) rotateY(20deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    }
    50%{
        background: rgb(16, 16, 16);transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        border: solid 3px rgb(255, 255, 255);
    }
    100%{
        background: rgb(16, 16, 16);transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(-0.1deg) rotateY(-20deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        border-left: solid 3px rgb(0, 255, 21);
        /* clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%); */
    }
}



.section-header{
    font-size: 270%;
    text-align: center;
    font-weight: 100;
    font-family: "WixMadeforDisplay-extraBold";
     letter-spacing: 1px;
     word-spacing: 2px;
     
     padding-top: 2vh;
     line-height: 7vh;
     margin-top: -2vh;

     /* background: linear-gradient(150deg, rgb(182, 0, 179) 0%, rgb(3, 96, 201) 100%);
     -webkit-background-clip: text;
     background-clip: text;
     color: transparent;
     width: fit-content; */
     color: rgb(255, 255, 255);

    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
     -webkit-background-clip: text;
     background-clip: text;
     color: transparent;

     

}

.sub-heading{
    font-size: 130%;
    text-align: center;
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    color: rgb(193, 193, 193);
    padding-bottom: 2vh;
    
}

.section-partition{
    display: flex;
    flex-direction: row;
}

.right-section{
    background-color: #a3ebe5;
   
    height: 90vh;
    width: 40vw;
    right: 5vw;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: transparent;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
    border-radius: 15px;
    background-color: transparent;
    overflow-x: hidden;
    overflow-y: hidden;
    
}

.left-section{
    margin-top: 0vh;
    width: 40vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: auto;
    margin-bottom: auto;

}

.feature-switcher{
    display: flex;
    flex-direction: row;
    margin-left: 5vw;
    margin-top: 7vh;
    align-items: center;
    /* background-color: #4800a0; */
}

.progress-bar-section{
    padding-left: 8vmin;
    padding-right: 3vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.up-icon{
    height: 6vh;
    width: auto;
    cursor: pointer;
    background: transparent;
    border-radius: 50%;
    padding: 10px;
    border: solid 1px rgb(103, 103, 103);
}

.down-icon{
    transform: rotate(180deg);
    height: 6vh;
    width: auto;
    cursor: pointer;
    background: transparent;
    border-radius: 50%;
    padding: 10px;
    border: solid 1px rgb(103, 103, 103);
}


.down-icon:hover{
    box-shadow: 0 -10px 30px rgba(255, 255, 255, 0.3);
    transition: all 0.5s ease;
}


.up-icon:hover{
    box-shadow: 0 -10px 30px rgba(255, 255, 255, 0.3);
    transition: all 0.5s ease;
}

/* .indicate-to-click{
    animation: attractArrow 1s ease-in-out infinite alternate;
} */
/* 
@keyframes attractArrow {
    from{
        border: solid 2px rgb(0, 0, 0);
        box-shadow: none;
    }
    to{
        border: solid 2px rgb(70, 70, 70);
        box-shadow: 0 10px 20px rgba(255, 255, 255, 0.5);
    }
} */

.feature-bar{
    position: relative;
    height: 20vh;
    width: 0.15vw;
    background-color: rgb(0, 0, 0);
    background-color: rgb(233, 232, 254);
}

.feature-progress-bar{
    height: 0;
    width: 0.25vw;
    background-color: #b2b2b2;
    position: absolute;
    left: -0.05vw;
    transition: all 0.5s ease;
    background-color: rgb(73, 0, 141);
    box-shadow: 4px 0 16px rgba(255, 255, 255, 0.2), -4px 0 8px rgba(0, 0, 0, 0.2);
}




.feature-headings-section{
    font-size: 130%;
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    color: rgb(156, 156, 156);
    padding-left: 1vmin;
    line-height: 6vmin;
    font-weight: 900;
}


.feature-button{        
    /* background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%); */
    padding: 0.1px;
    border-radius: 10px;
    width: fit-content;
}


.feature-heading{
    background-color: inherit;
    border-radius: 10px;
    padding: 2px 15px;
    transition: all 1s;
    font-size: 90%;
}

.feature-button-selected {
    /* background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%); */
}

.feature-selected {
    /* background-color: black; */
    color: white;
    border: solid 1px rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(191, 123, 255, 0.3);
    background-color: transparent;
    font-size: 100%;
    transform: translateX(15px);
}


.phone-bg{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    top: -60px;
    left: 80px;
    opacity: 0.6;
}

.background-stars{
    background-color: #a3ebe5;
}
.image-container {
    position: relative;
    width: 100%; /* Set your desired width */
    height: 60vh; /* Set your desired height */
    /* overflow: hidden; */
    /* padding: 20px; */
}
.phone-drops-bg{
    width: 80%;
    height: 95%;
    position: absolute;
    top: 10%;
    left: 10%;
    background-image: url('../assert/images/Drawing.png'); /* Set the path to your background image */
    background-size: cover; /* Adjust based on your needs */
    background-position: center; /* Adjust based on your needs */
    opacity: 0.4;
    animation: popdance 4s ease infinite;
}

@keyframes popdance {
    0% {
        transform:scale(1)
    }
    50% {
        transform:scale(1.02)
    }
    100% {
        transform:scale(1)
    }
} 

.phone-screen{
    max-height: 55vh;
    width: 80%;
    object-fit: contain;
    z-index: 1;
    transform: rotateX(0deg) rotateY(0deg);
    transform: scale(1.045);
    /* transition: transform 2s ease; */
            object-fit: cover; /* Ensure the image covers the container */
            position: absolute;
            top: 10%;
            left: 0;
}

.phone-screen-rotate{
    animation: tiltPhone 2s ease;
}

@keyframes tiltPhone {
    0% {
        transform: scale(1)
    }
    100% {
        transform: scale(1.045)
    }
}

.feature-description{
    font-size: 130%;
    color: rgb(190, 190, 190);
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    text-align: center;
    padding: 5vmin;
    font-weight: 700;
    transition: all 2s ease;
    z-index: 1;
    font-weight: 900;
    line-height: 5vh;
    height: 18vh;
    margin-top: -20vh;
    /* background-color: #a3ebe5; */
}

.get-staretd-button{
    text-align: center;
    align-self: center;
    z-index: 1;
    margin-bottom: 10vh;
}

  .corner-circle {
    position: relative;
    width: 50vh;
    height: 50vh;
    right: 200px;
    bottom: -50px;
    right: -50px;
    background: radial-gradient(closest-side, #a6e0f9, #ffffff, white);
    background: radial-gradient(circle closest-side at center, #ccc7ff 80%, transparent 100%, transparent);
    transition: all 3s ease;
}


.mobile-screen-container {
    position: relative;
  }
  
  .phone-screen {
    width: 200px; /* Adjust as needed */
    height: 400px; /* Adjust as needed */
    max-height: 50vh;
    height: 50vh;
    width: 100%;
    object-fit: contain;
    /* z-index: 1; */
    /* transform: rotateX(10deg) rotateY(10deg); */
    transition: transform 2s ease;
  }




  .line{
    width: 70%;
    height: 70px;
    position: relative;
    overflow: hidden;
    span{
        position: absolute;
        font-size: 5rem;
        line-height: 42rem;
      }
  }


  .mobile-3d{
    margin-left: auto ;
    margin-right: auto ;
    background-color: transparent;
    margin-left: 5vw;
    margin-top: 7vh;
   scale: 0.8;
  }


  .pointer-light{
    z-index: -100;
    top:0;
    left: 0;
    position: fixed;
    height: 100px;
    width: 100px;
    background-color: #ccc7ff;
    border-radius: 50%;

    background: rgba(180, 123, 255, 0.3);
    background: radial-gradient(#ce80ff, #ff1818);
    background: linear-gradient(100deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);

    background: radial-gradient(closest-side, #561db1, #4800a0, #000000);
  box-shadow:
    rgba(255, 255, 255, .2) 0 0 12px 0,
    rgba(255, 255, 255, .2) 0 0 12px 0,
    rgba(255, 255, 255, .2) 0 0 16px 0,
    rgba(255, 255, 255, .2) 0 0 16px 0,
    rgba(255, 255, 255, .2) 0 0 24px 4px,
    rgba(255, 255, 255, .2) 0 0 48px 6px;
  border-radius: 50%;
  filter: blur(32px);
  opacity: .5;
  pointer-events: none;
  user-select: none;
  }



  





