.cu-header{
   padding-top: 7vmin;
    font-family: "WixMadeforDisplay-bold";
    font-size: 300%;
    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
    text-align: center;
    height: fit-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.contact-section{
    background-color: rgb(0, 0, 0);
    height: 100vh;
    width: 100vw;
    background-image: url("../assert/images/bg2.png");
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: bottom left ; */
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    padding-top: 10vh;
}

.cu-section{
    width: 40%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cu-address{
    font-family: "WixMadeforDisplay-regular";
    font-size: 110%;
    color: rgb(180, 180, 180);
    padding-bottom: 2vmin;
    text-align: center;
}

.cu-line {
    align-items: top;
    padding-top: 2vmin;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "WixMadeforDisplay-regular";
    font-size: 120%;
    color: white;
}

.cu-icon {
    height: 4vmin;
    width: 4vmin;
    margin-right: 1vmin;
}

.maps{
    background-color: rgb(25, 25, 49);
    width: 40%;
    height: 70%;
    display: flex;
    align-items: top;
}

.map-container{
    border: 0;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    /* filter: invert(100%); */
}

