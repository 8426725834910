.industrial-page{
    padding-top: 1vh;
    background-color: rgb(0, 0, 0);
    height: fit-content;
    width: 100vw;
}

.industial-intro-pag{
    height: 100vh;
    width: 100vw;
    /* background-image: url("../assert/images/bg-corss.png"); */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #000000;

    height: 100vh;
    background:
      linear-gradient(200deg, var(--gradient-start), var(--gradient-mid) 48%, var(--gradient-end) 76%, var(--gradient-end) 92%),
      linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
      linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
      var(--dot-color);
    background-blend-mode: overlay;
    color: white;
    /* border-radius: 0 0 110% 10% ; */
    
}

.transparent-section{
    height: 100vh;
    width: 85vw;
    /* background-color: #000000; */
    /* border-right: solid 1px rgb(33, 12, 84);
    border-left: solid 1px  rgb(33, 12, 84); */
    border-radius: 0% 0% 0% 0%;
    padding: 5vmin;
    position: absolute;
    top:0;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    
}

.is1-heaeder{
    font-family: "WixMadeforDisplay-Bold";
    color: rgb(255, 255, 255);
    font-size: 230%;
    text-align: center;
    /* background-color: black; */
    border-radius: 15px;
    padding: 2vmin;
    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.is1-subHeader{
    font-family: "WixMadeforDisplay-regular";
    color: rgb(152, 151, 151);
    font-size: 110%;
    text-align: center;
    /* background-color: black; */
    border-radius: 15px;
    margin-top: 2vmin;
}

.icon-points-section{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 13vmin;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    
}

.icon-and-expalantion{
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 11vw;
}

.blur-bg{
    position: relative;
}

.blur-bg::before{
    content: '';
    /* background: linear-gradient(145deg, #ac7fdf, #2d2d2d 82%); */
    background-size: cover;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(20px);

}

.i-icon{
    z-index: 2;
    height: 10vmin;
    width: auto;
    background-color: #262627;
    border-radius: 50%;
    padding: 2vmin;
}

.i-icon-explanation{
    font-family: "WixMadeforDisplay-regular";
    color: rgb(244, 244, 244);
    font-size: 100%;
    text-align: center;
    margin-top: 3vmin;
}

.industrial-book-demo{
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: 5vmin;
    
}

.why-section-parent{
    background-image: url("../assert/images/gradeint-bg1.png");
    background-size: cover;
}

.why-section{
    height: 100vh;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
}


.why-heading{
    font-family: "WixMadeforDisplay-regular";
    color: rgb(103, 90, 90);
    font-size: 230%;
    text-align: center;
    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    transition: ease all 0.5s;
    opacity: 0;
    padding-bottom: 2vh;
}

.why-explanation{
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    color: rgb(255, 255, 255);
    font-size: 135%;
    text-align: center;
    width: fit-content;
    transition: ease all 0.5s;
    opacity: 0;
}

.with-crm{
    padding-top: 10vh;
    max-height: 100vh;
}

.tiny-card-heading{
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    color: rgb(255, 255, 255);
    font-size: 250%;
    text-align: center;
    /* background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2vmin;

    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.tiny-cards-container{
    width: 100vw;
}

.tiny-card{
    border-right: solid 1px rgb(77, 76, 76);
    background-color: rgb(16, 16, 16);
    /* padding: 1px 1px; */
    border-radius: 25px;
    transition: ease all 0.3s;
    
}

.card-foreground{
    position: relative;
    background-color: rgb(16, 16, 16);
    height: 100%;
    width: 100%;
    padding: 3vmin 3vmin;
    border-radius: 25px;
}

.tiny-card:hover {
    background: linear-gradient(145deg, #f8522e, #fcf74a 48%, #aa3fff 76%, #6557ff 92%);
    box-shadow: 0 0 25px 0 rgba(251, 24, 24, 0.5);
     
}

.card-foreground:hover{
    background: linear-gradient(90deg, rgb(16, 16, 16) 60%, rgb(22, 22, 22) 85%)
}

.card-foreground:hover .tiny-card-icon{
    transform: scale(1.1);
}

.card-foreground:hover .tiny-card-name{
    transform: translateX(0.5vmin);
}


.tiny-card-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5vmin;
    padding-right: 5vmin;
    margin-bottom: 1.75vmin;
    min-height: 6vmin;
    
}

.tiny-card-icon{
    transition: ease all 1s;
    height: 5vmin;
    width: 5vmin;
    margin-right: 2vmin;
}

.tiny-card-name{
    font-family: "WixMadeforDisplay-regular";
    color: rgb(255, 255, 255);
    font-size: 110%;
    transition: ease all 0.5s;

    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.tiny-card-description{
    font-family: "WixMadeforDisplay-regular";
    word-spacing: 1px;
    color: rgb(205, 205, 205);
    font-size: 90%;
    padding-left: 5vmin;
    padding-right: 5vmin;
    margin-bottom: 1vmin;
}

.parent-ic-card-section{
    padding-top: 30vmin;
}

.parent-ic-header{
    font-family: "WixMadeforDisplay-bold";
    word-spacing: 1px;
    color: rgb(255, 255, 255);
    font-size: 250%;
    text-align: center;
    width: 75%;
    margin-left: auto;
    margin-right: auto;

}

.parent-ic-subHeader{
    font-family: "WixMadeforDisplay-regular";
    word-spacing: 1px;
    color: rgb(121, 121, 121);
    font-size: 150%;
    text-align: center;
    padding-top: 2vmin;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}



