.logo{
    margin-left: 5vh;
    font-size: 130%;
    color: #5d5d5d;
    height: fit-content;
    border-radius: 25px;
}

.top-bar{
    width: 100vw;
    font-family : "BebasNeue";
    font-size: 100%;
    letter-spacing: 0.75px;
    height: fit-content;
    display: flex;
    font-weight: 300;
    right: 0;
    font-family: "WixMadeforDisplay-regular";
    font-weight: 100;
    color: rgb(225, 225, 225);
    /* box-shadow: 0px 4px 16px rgba(170, 42, 255, 0.2); */
   
    align-items: center;
    position: relative;
    background-color: rgba(0, 0, 0);
    overflow-x: hidden;
    box-shadow: 0px 1px 16px rgba(201, 0, 0, 0.4);
    /* position: fixed;
    z-index: 10; */
}

.top-bar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5px; /* Adjust the height of the border */
  background: linear-gradient(100deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);

}

.header-conents{
  padding: 0;
  margin-right: 0px;
  margin-left: 0px;
  /* background-color: #7d2828; */
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
}

.top-bar-item {
    padding: 2.3vmin;
    border-radius: 5px;
    text-align: center;
    width: fit-content;
    /* background-color: #362ea3; */
    margin-left: 2vmin;
    margin-right: 2vmin;
    max-height: 70px;
    color: #ffffff;
  }

  .top-bar-item::after{
    top:1.5px;
    position: relative;
    width: 1px;
    height: 2px;
    background-color: #000000;
    content: "";
    display: block;
    left: 50%;
  }


  .top-bar-item:hover::after {
    background: linear-gradient(100deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    animation: expandUnderline  1.5s forwards;
    box-shadow: 0px 8px 16px rgb(221, 127, 255);
  }

  @keyframes expandUnderline {
    0%{

    }
    100%{
        width: 100%;
        left: 0%;
    }
  
  }
