/* @font-face {
    font-family: "WixMadeforDisplay-Regular";
    src: url(.//fonts/WixMadeforDisplay-Regular.ttf);
}

@font-face {
    font-family: "WixMadeforDisplay-Bold";
    src: url(.//fonts/WixMadeforDisplay-Bold.ttf);
}

@font-face {
    font-family: "WixMadeforDisplay-extraBold";
    src: url(.//fonts/WixMadeforDisplay-ExtraBold.ttf);
} */



.all-features-section{
    width: 100vw;
    background-color: rgb(0, 0, 0);
    padding-top: 4min;
    padding-left: 13vmin;
    padding-right: 13vmin;
    padding-top: 5vh;
}

.features-page-heading {
    width: fit-content;
    font-size: 300%;
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    color: #ffffff;
    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: center;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.all-features{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.features-left-section{
    margin-top: 10vh;
    width: 40vw;
    overflow-x: hidden;
}

.features-right-section{
    height: 100vh;
    width: 60vw;
    position: sticky;
    top:0;
    display: flex;
    justify-content:end;
    align-items: center;
    overflow-x: hidden;

    /* background-color: #453fff; */
}


.feature-list-container{
    margin-bottom: 5vh;
    background-color: rgb(11, 11, 11);
    padding: 3vmin;
    border-radius: 10px;
}

.feature-heading-name{
    padding: 2vmin;
    font-size: 160%;
    color: transparent;
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    text-transform:uppercase;
    -webkit-text-stroke: 0.5px  rgb(190, 86, 255);
    -webkit-text-fill-color:transparent;
    background-image: linear-gradient(to right, #6a00ff, #ff9500);
    -webkit-background-clip: text;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}


.sub-heading-container{
    display: flex;
    flex-wrap: wrap;
}

.feature-names{
    font-size: 130%;
    color: #ffffff;
    border-radius: 10px;
    padding: 7px 15px;
    margin-right: 10px;
    margin-bottom: 20px;
    font-family: "WixMadeforDisplay-Regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    border: solid 0.1px rgb(44, 44, 44);
    /* box-shadow: 0 0 10px 0 rgba(191, 123, 255, 0.3); */
    box-shadow: 0 3px 4px rgba(118, 118, 118, 0.1);
    align-items: center;
    /* background-color: blue; */
    /* width:max-content; */
}

.feature-name-icon{
    height: 3vh;
    /* padding: 2px; */
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.display-container-border{
    height: 80vh;
    width: 50vw;
    position: relative;
    
    /* background: linear-gradient(140deg, rgba(101, 87, 255, 0.2), rgba(170, 63, 255, 0.2) 48%, rgba(154, 163, 34, 0.2) 76%, rgba(248, 82, 46, 0.2) 92%); */
    /* background: linear-gradient(170deg, rgb(76, 0, 163, 0.1) 50%, rgb(17, 17, 17) 78%); */
    /* background-color: #1a1a1a; */
    padding: 2px;
    border-radius: 10px;
    z-index: 0;
    /* box-shadow: 5px 14px 50px 7.6px rgb(245, 0, 0); */
    /* box-shadow: rgba(0, 0, 0, 1) 0px -50px 36px -28px inset; */
    /* box-shadow: rgb(102, 255, 0) 1px 1px 40px 0px inset; */
    /* box-shadow: rgb(0, 0, 0) 0px 0px 10px 5px inset; */
     /* background-image: url('path/to/your/image.jpg');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; */
    /* border: solid 1px rgb(90, 90, 90); */


    /* background-image: linear-gradient(rgba(26, 26, 26, .7), rgba(0, 0, 0, .06));
    border: 0.5px solid rgba(161, 159, 159, 0.5);
    box-shadow: 4px 8px 20px rgba(65, 65, 65, 0.6);
    background-image: url('./images/gradBg.png');
    background-size: cover;
    background-repeat: no-repeat; */


    /* background: linear-gradient(-10deg, rgba(74,43,45,1) 10%, rgba(76,36,89,1) 20%, rgb(20, 20, 20) 35%); */

}

.display-container{
    /* background: url('./images/gradBg.png');
    background-size: cover; */
    /* background-color: rgba(57, 57, 57, 0.8); */
    height: 100%;
    width: 100%;
    border-radius: 10px;
    padding-top: 5%;
    display: flex;
    flex-direction: row;
}

.card-left-section{
    width: 60%;
    padding-left: 5%;
    /* background-color: #9693b2; */
}


.card-right-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    /* background-color: #6557ff; */
}

.card-header-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.card-header-icons{
    background: url('../assert//images/iconExcelWhite.png');
    height: 6vh;
    width: 6vh;
    padding-right: 1vw;
    background-size: cover; 
}


.card-heading{
    padding-left: 10px;
    height: fit-content;
    font-size: 250%;
    font-family: "WixMadeforDisplay-Bold";
    /* letter-spacing: 1px;
    word-spacing: 2px; */
    color: rgb(7, 7, 7);
    color: rgb(219, 219, 219);
    /* background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; */
    /* border-bottom: solid 1.5px rgb(221, 221, 221); */
   
}

.card-description{
    font-size: 120%;
    font-family: "WixMadeforDisplay-extraBold";
    letter-spacing: 0.5px;
    word-spacing: 1px;
    color: rgb(24, 24, 24);
    color: rgb(146, 146, 146);
    padding-top: 3vh;
    padding-bottom: 5vh;
}

.points-container{
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: flex-start;
    padding-bottom: 3vh;
    /* background-color: green; */
}


.arrow-symbol{
    background-size: cover; 
    background-color: inherit;
    height: 3vh;
    width: 3vh;
    /* background-color: violet; */
    /* padding: 5px; */
    /* padding-top: 10px; */
    margin: 5px;
    margin-top: 7px;
    margin-right: 15px;
    /* border: solid 5px green; */
    /* border-color: green; */
}

.card-points{
    font-size: 120%;
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    color: rgb(221, 221, 221);
    /* padding-bottom: 1.5vh; */
    /* background-color: turquoise; */
}

.card-get-started-btn{
    display: flex;
    justify-content: center;
}

.card-mobile-3d{
    scale: 0.9;
}


