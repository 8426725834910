.pricing-section{
    min-height: 100vh;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.pricing-page-header{
    font-family: "WixMadeforDisplay-regular";
    font-size: 220%;
    word-spacing: 1px;
    color: white;
    text-align: center;
}

.pricing-sub-page-header{
    font-size: 130%;
    font-family: "WixMadeforDisplay-regular";
    word-spacing: 1px;
    color: rgb(122, 122, 122);
    text-align: center;
    padding-bottom: 3vh;
}

.pricing-card-section{
    margin-top: 2vh;
    height: 65vh;
    width: 120vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1240px;
    min-height: 550px;
    min-width: 950px;
}

.pricing-card-border {
    height: 100%;
    width: 30%;
    background-color: rgb(27, 27, 27);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 1px;
    border-radius: 20px;
    /* transition: background 0.5s ease, transform 0.5s ease; */
    box-shadow: 0 0 10px rgba(123, 88, 88, 0.1);
    max-width: 380px;
    height: fit-content;
}

.pricing-card-border:hover{
    transition: scale 0.75s ease;
    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    box-shadow: 0 0 10px rgba(164, 10, 10, 0.5);
    scale: 1.04;
    max-width: 380px;
}

.pricing-card{
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgb(19, 19, 19) 0%, rgb(0, 0, 0) 70%);
    border-radius: 20px;
    padding: 3vh 2vh;
    height: fit-content;
}

.plan-name{
    font-family: "WixMadeforDisplay-bold";
    font-size: 110%;
    word-spacing: 1px;
    color: rgb(223, 223, 223);
    text-align: center;
}

.plan-pricing{
    font-family: "WixMadeforDisplay-regular";
    font-size: 140%;
    word-spacing: 1px;
    color: rgb(188, 188, 188);
    text-align: center;
    background: linear-gradient(145deg, #6557ff, #aa3fff 28%, #fcf74a 66%, #f8522e 72%);
     -webkit-background-clip: text;
     background-clip: text;
     color: transparent;
}

.plan-pricing::after{
    content: "/User";
    font-size: 70%;
    color: #727272;
}

.custom-hr{
    height: 1px;
    color: #d8d8d8;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.plan-name-parent{
    display: flex;
    flex-direction: row;
}

.plan-features{
    font-family: "WixMadeforDisplay-regular";
    font-size: 90%;
    word-spacing: 2px;
    letter-spacing: 0.5px;
    color: rgb(234, 234, 234);
    text-align: left;
    padding: 1vw 2vw;
}

.plan-feature{
    /* border: solid 1px red; */
    padding-bottom: 0.3vw;
}

.pricing-tick-symbol{
    background-size: cover; 
    background-color: inherit;
    height: 2.3vh;
    width: 2.3vh;
    margin: 5px;
    margin-top: 7px;
    margin-right: 1.5vh;
}

.get-trail-button{
    font-size: 80%;
}
