.FAQ-section{
    background-color: rgb(0, 0, 0);
    height: calc(10vmin + fit-content);
    width: 90vw;
    margin-right: auto;
    margin-left: auto;
    width: 75vw;
    padding-top: 10vh;
    margin-top: 15vh;
    padding-bottom: 20vh;
}

.FQA-header{
    font-family: "WixMadeforDisplay-regular";
    word-spacing: 1px;
    color: #000000;
    font-size: 350%;
    transition: ease all 0.5s;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5vmin 2vmin;
    margin-bottom: 3vmin;

    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
     -webkit-background-clip: text;
     background-clip: text;
     color: transparent;
}

.QA-section{
    border-top: solid 1.5px rgb(21, 21, 21);
    padding-top: 2vmin;
    transition: ease all 2s;
    padding: 3.5vmin;
}

.active-QA-section{
    transition: ease all 2s;
    border-image: linear-gradient(to right, rgb(50, 49, 49), rgb(120, 120, 120)) 1;
    animation: temp 0.5s forwards;
}

@keyframes temp {
    0%{
        border-width: 0;
    }
    100%{
        border-width: 100%;
        border-image: linear-gradient(145deg, #6557ff, #aa3fff 38%, #fcf74a 56%, #f8522e 72%) 1;
    }
}

.QA-section:hover > .FAQ-answer{
    /* left: 0;
    opacity: 1;
    height: auto;
    height: fit-content;
    padding-bottom: 1vmin;
    padding-top: 1vmin; */
}

.QA-section:hover > .FAQ-question{
    /* transition: ease all 0.75s;
    height: fit-content;
    font-family: "WixMadeforDisplay-Bold";
    color: #ffffff; */
}

.FAQ-question::before{
    content: attr(data-index)". ";
    font-size : 80%;
    color: #2d2d2d;
}

.FAQ-question{
    font-family: "WixMadeforDisplay-regular";
    word-spacing: 1px;
    color: #c0c0c0;
    font-size: 130%;
    transition: ease all 0.5s;
}

.active-question{
    font-family: "WixMadeforDisplay-regular";
    word-spacing: 1px;
    color: #ededed;
    font-size: 145%;
    transition: ease all 0.5s;
    font-family: "WixMadeforDisplay-Bold";
}

.FAQ-answer{
    font-family: "WixMadeforDisplay-regular";
    word-spacing: 1px;
    color: #858585;
    font-size: 100%;
    height: 0;
    overflow: hidden;
    transition: ease all 0.5s;
}

.active-answer{
    font-family: "WixMadeforDisplay-regular";
    word-spacing: 1px;
    color: #858585;
    font-size: 100%;
    height: auto;
    height: fit-content;
    margin-bottom: 1vmin;
    padding-bottom: 2vmin;
    padding-top: 1vmin;
}