
                                                    /* gradient button */
                                                    
.get-started-btn{
    width: fit-content;
    text-align: center;
    align-self: center;
    border-radius: 25px;
    font-family: "WixMadeforDisplay-regular";
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: 700;
    font-size: 100%;
    text-transform: capitalize;
    background: rgb(238,174,202);
    background: linear-gradient(150deg, rgb(247, 182, 210)0%, rgb(148, 233, 217) 100%);
    color: rgb(45, 45, 45);
    background:  rgb(234, 234, 234);
    color: rgb(255, 255, 255);
    z-index: 1;
    padding: 1px;
    background-color:  #d1d1d1;
    background: linear-gradient(145deg, #6557ff, #aa3fff 48%, #fcf74a 76%, #f8522e 92%);
    transition: ease all 0.5s;
}

.btn-content {
    background-color:  black;
    border-radius: 25px;
    padding: 10px 25px;
    color: #ffffff;
    position: relative;
}

.btn-arrow {
    margin-left: 1vw;
    height: 3vh;
    width: 3vh;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform 1.5s ease;
    scale: 1.2;
}

.get-started-btn:hover{
    box-shadow: 0 0 28px 0 rgba(251, 24, 24, 0.5);
    
}

.get-started-btn:hover > .btn-content > .btn-arrow{
    transform: rotate(360deg);
    scale: 1.4;
}


